import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, Table, Typography, Spin, Select, DatePicker, Space, Radio, Card, Modal, Button, Checkbox } from 'antd';
import { TableOutlined } from '@ant-design/icons';
import moment from 'moment';
import PremiumAverage from './PremiumAverage';
import PremiumAverageAgent from './PremiumAverageAgent';
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;


class ActivitySummary extends Component {
    constructor(props) {
      super(props);
        this.state = {
            dateFrom: moment().format("MM/DD/YYYY"),
            dateTo: moment().format("MM/DD/YYYY"),
            selectedColumns: [],
            columns: [],
            modalVisible: false,
            dnqFilter: "all",
            trendingFilter: localStorage.getItem('premiumAverageFilter') != null ? localStorage.getItem('premiumAverageFilter') : "premium",
            productFilter: "Allstate"
        }
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleDateRadioChange = this.handleDateRadioChange.bind(this);
        this.handleColumnChange = this.handleColumnChange.bind(this);
        this.handleDnqRadioChange = this.handleDnqRadioChange.bind(this);
        this.handleTrendingFilterChange = this.handleTrendingFilterChange.bind(this);
    }
    componentDidMount() {
        console.log(this.props.loggedInAgentId);
        const agencyId = localStorage.getItem('agencyId');
        console.log(this.props.quoteAgentSummary);
        this.props.getQuoteAgentSummary(agencyId, moment().format("MM/DD/YYYY"), moment().format("MM/DD/YYYY"));
        this.props.getEMQuoteAgentSummary(agencyId, moment().format("MM/DD/YYYY"), moment().format("MM/DD/YYYY"));
    }
    componentDidUpdate(prevProps){
        //console.log("Seed: " + this.props.seed + " = " + prevProps.seed);
        if(prevProps.quoteLog != this.props.quoteLog & this.props.activitySummaryRefresh == true){
            const agencyId = localStorage.getItem('agencyId');
            this.props.getQuoteAgentSummary(agencyId, moment().format("MM/DD/YYYY"), moment().format("MM/DD/YYYY"));
        }
    }
    
    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        const dnqFilter = this.state.dnqFilter == "all" ? false : true;
        if(dateFrom != ''){
            this.props.getQuoteAgentSummary(agencyId, dateFrom, dateTo);
            this.props.getEMQuoteAgentSummary(agencyId, dateFrom, dateTo);
        }
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo,
            dateFilter: ''
        });
    }
    handleColumnChange(value){
        this.setState({selectedColumns: value});
    }
    handlePanelChange = (open) => {
        if(open){
            this.setState({dateFrom: null, dateTo: null})
        }
    }
    handleCloseModal = () => {
        this.setState({
            modalVisible: false,
        });
        localStorage.setItem('selectedAgentColumns', this.state.selectedColumns);
        console.log(this.state.selectedColumns);
    }
    handleDateRadioChange(e) {
        this.setState({
            dateFilter: e.target.value
        });
        var dateFrom = '';
        var dateTo = ''; 
        const agencyId = localStorage.getItem('agencyId');
        const dnqFilter = this.state.dnqFilter == 'all' ? false : true;
        if(e.target.value == 'today'){
            dateFrom = moment().format('MM/DD/YYYY');
            dateTo = moment().format('MM/DD/YYYY'); 
        }
        if(e.target.value == 'thisWeek'){
            dateFrom = moment().startOf('week').format('MM/DD/YYYY');
            dateTo = moment().endOf('week').format('MM/DD/YYYY'); 
        }
        else if(e.target.value == 'lastWeek'){
            dateFrom = moment().subtract(1, 'week').startOf('week').format('MM/DD/YYYY');
            dateTo = moment().subtract(1, 'week').endOf('week').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'thisMonth'){
            dateFrom = moment().startOf('month').format('MM/DD/YYYY');
            dateTo = moment().endOf('month').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'lastMonth'){
            dateFrom = moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY');
            dateTo = moment().subtract(1, 'month').endOf('month').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'all'){
            dateFrom = '';
            dateTo = '';
        }
        this.props.getQuoteAgentSummary(agencyId, dateFrom, dateTo);
        this.props.getEMQuoteAgentSummary(agencyId, dateFrom, dateTo, dnqFilter);
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo 
        });
    }
    handleDnqRadioChange(e) {
        this.setState({
            dnqFilter: e.target.value
        });
    }
    handleTrendingFilterChange(filter){
        console.log(filter);
        this.setState({trendingFilter: filter});
    }
    render() {
        const summaryColumns = [
            {
                title: 'Agent',
                dataIndex: 'agent',
                key:'agentName',
                caption: 'Agent'
            },
            {
                title: 'Quoted Households',
                dataIndex: 'count',
                align: 'center',
                key: 'count',
                caption: 'Quoted Households'
            },
            {
                title: 'Quoted Policies',
                dataIndex: this.state.dnqFilter == "all" ? 'quotedPolicies' : 'quotedPoliciesDnq',
                align: 'center',
                key: 'quotedPolicies',
                caption: 'Quoted Policies'
                
            },
            {
                title: 'Quoted Items',
                dataIndex: this.state.dnqFilter == "all" ? 'quotedItems' : 'quotedItemsDnq',
                align: 'center',
                key: 'quotedItems',
                caption: 'Quoted Items'
            },
            {
                title: title => <strong>Written Households</strong>,
                //title:  () => { 
                //     var total = 0;
                //     for(var i=0;i<data.length;i++){
                //       total += data[i].writtenHouseholds;
                //     }
                //     return <div>Written Households<br /> <Badge count={total}></Badge></div>;
                // },
                dataIndex: 'writtenHouseholds',
                align: 'center',
                key: 'writtenHouseholds',
                render: text => <strong>{text}</strong>,
                caption: 'Written Households'
            },
            {
                title: title => <strong>Written Policies</strong>,
                dataIndex: 'writtenPolicies',
                align: 'center',
                key: 'writtenPolicies',
                render: text => <strong>{text}</strong>,
                caption: 'Written Policies'
            },
            {
                title: title => <strong>Written Items</strong>,
                dataIndex: 'writtenItems',
                align: 'center',
                key: 'writtenItems',
                render: text => <strong>{text}</strong>,
                caption: 'Written Items'
            },
            {
                title: title => <strong>Written Premium</strong>,
                dataIndex: 'writtenPremium',
                render: text => <span style={{textAlign: 'center', fontWeight: 'bold'}}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(text)}</span>,
                align: 'center',
                key: 'writtenPremium',
                caption: 'Written Premium'
            },
            {
                title: title => <strong>Trending</strong>,
                dataIndex: 'trending',
                render: text => <span style={{textAlign: 'center', fontWeight: 'bold'}}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(text)}</span>,
                align: 'center',
                key: 'trending',
                caption: 'Trending'
            },
            {
                title: title => <strong>Trending Items</strong>,
                dataIndex: 'trendingItems',
                render: text => <span style={{textAlign: 'center', fontWeight: 'bold'}}>{Math.round(text)}</span>,
                align: 'center',
                key: 'trendingItems',
                caption: 'Trending Items'
            },
            {
                title: title => <strong>Close Ratio</strong>,
                dataIndex: this.state.dnqFilter == "all" ? 'closeRatio' : 'closeRatioDnq',
                align: 'center',
                key: 'closeRatio',
                render: text => <strong>{text}</strong>,
                caption: 'Close Ratio'
            },
            {
                title: 'Quoted Bundles',
                dataIndex: 'quotedBundles',
                key: 'quotedBundles',
                align: 'center',
                key: 'quotedBundles',
                caption: 'Quoted Bundles'
            },
            {
                title: 'Quoted Bundle Ratio',
                dataIndex: 'quotedBundleRatio',
                align: 'center',
                key: 'quotedBundleRatio',
                caption: 'Quoted Bundle Ratio'
            },
            {
                title: 'Written Bundles',
                dataIndex: 'writtenBundles',
                key: 'writtenBundles',
                align: 'center',
                key: 'writtenBundles',
                caption: 'Written Bundles'
            },
            {
                title: 'Written Bundle Ratio',
                dataIndex: 'writtenBundleRatio',
                align: 'center',
                key: 'writtenBundleRatio',
                caption: 'Written Bundle Ratio'
            },
            {
                title: 'Self Gen Quotes',
                dataIndex: 'selfGenerated',
                align: 'center',
                key: 'selfGenerated',
                caption: 'Self Generated'
            },
            {
                title: '1CC',
                dataIndex: 'oneCallClose',
                align: 'center',
                key: 'oneCallClose',
                caption: '1CC'
            },
            {
                title: '1CC Percent',
                dataIndex: 'oneCallClosePercent',
                align: 'center',
                key: 'oneCallClosePercent',
                render: text => <span style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(text)}</span>,
                caption: '1CC Percent'
            },
        ];
        //const filteredColumns = this.state.selectedColumns.length == 0 ? summaryColumns : summaryColumns.filter(c => this.state.selectedColumns.includes(c.title));
        const filteredColumns = this.state.trendingFilter == "premium" ? summaryColumns.filter(c => c.key != "trendingItems") : summaryColumns.filter(c => c.key != "trending");
        return (
            <div>
                {
                    this.props.onlySelf == false ?
                    <PremiumAverage onFilterChange={this.handleTrendingFilterChange}/>
                    :
                    <PremiumAverageAgent loggedInAgentName={this.props.loggedInAgentName} loggedInAgentId={this.props.loggedInAgentId} />
                }
                {/* <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "auto", boxShadow: '5px 5px 5px #ccc', marginTop: 10}}>  */}
                <div style={{backgroundColor: '#f4f4f4'}}>
                    <Row style={{paddingTop: 10}}>
                        <Col sm={24}>
                            <Row>
                                <Col span={12}>
                                    <Title level={4}>AGENT ACTIVITY SUMMARY</Title>
                                </Col>
                                <Col span={8}>
                                    <Radio.Group size="small" defaultValue="Allstate" buttonStyle="solid" onChange={(e) => this.setState({productFilter: e.target.value})} value={this.state.productFilter}>
                                        <Radio.Button value="Allstate">Allstate</Radio.Button>
                                        <Radio.Button value="EM">Expanded Marketplace</Radio.Button>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            {!this.props.isActivityLoading &&
                            <Space>
                                <Radio.Group defaultValue="today" buttonStyle="solid" onChange={this.handleDateRadioChange} value={this.state.dateFilter}>
                                    <Radio.Button value="today">Today</Radio.Button>
                                    <Radio.Button value="thisWeek">This Week</Radio.Button>
                                    <Radio.Button value="lastWeek">Last Week</Radio.Button>
                                    <Radio.Button value="thisMonth">This Month</Radio.Button>
                                    <Radio.Button value="lastMonth">Last Month</Radio.Button>
                                </Radio.Group>
                                <RangePicker format={'MM/DD/YYYY'} allowEmpty={true}
                                    ranges={{
                                        Today: [moment(), moment()],
                                        'This Week': [moment().startOf('week'), moment().endOf('week')],
                                        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                    }}
                                    defaultValue={[moment().startOf('month'), moment().endOf('month')]}
                                    value={this.state.dateFrom == null ? [] : [moment(this.state.dateFrom), moment(this.state.dateTo)]}
                                    onChange={this.handleDateRangeChange}
                                    onOpenChange={this.handlePanelChange}
                                />
                                <Button shape="round" onClick={() => this.setState({modalVisible: true})} style={{display: 'none'}}>
                                    <TableOutlined /> Select Columns
                                </Button>
                                <Radio.Group defaultValue="all" buttonStyle="solid" onChange={this.handleDnqRadioChange} value={this.state.dnqFilter}>
                                    <Radio.Button value="all">All</Radio.Button>
                                    <Radio.Button value="excludeDnq">Exclude DNQ</Radio.Button>  
                                </Radio.Group>
                            </Space>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            {this.props.isActivityLoading ?
                                <div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading Agent Activity Summary</Title></p></div>:
                                    <Table dataSource={this.state.productFilter == "Allstate" ? this.props.quoteAgentSummary : this.props.emQuoteAgentSummary} size="small" pagination={false} columns={filteredColumns} summary={pageData => {
                                    let totalCount = 0;
                                    let totalWrittenHouseholds = 0;
                                    let totalWrittenItems = 0;
                                    let totalQuotedItems = 0;
                                    let totalQuotedItemsDnq = 0;
                                    let totalWrittenPremium = 0;
                                    let totalTrending = 0;
                                    let totalTrendingItems = 0;
                                    let totalQuotedPolicies = 0;
                                    let totalQuotedPoliciesDnq = 0;
                                    let totalQuotedBundles = 0;
                                    let totalWrittenBundles = 0;
                                    let totalWrittenPolicies = 0;
                                    let totalSelfGenerated = 0;
                                    let totalOneCallClose = 0;

                                    pageData.forEach(({ count, writtenHouseholds, writtenItems, quotedItems, quotedItemsDnq, writtenPremium, quotedPolicies, quotedPoliciesDnq, quotedBundles, writtenBundles, writtenPolicies, trending, selfGenerated, oneCallClose, trendingItems }) => {
                                        totalCount += count;
                                        totalWrittenHouseholds += writtenHouseholds;
                                        totalWrittenItems += writtenItems;
                                        totalQuotedItems += quotedItems;
                                        totalQuotedItemsDnq += quotedItemsDnq;
                                        totalWrittenPremium += writtenPremium;
                                        totalQuotedPolicies += quotedPolicies;
                                        totalQuotedPoliciesDnq += quotedPoliciesDnq;
                                        totalQuotedBundles += quotedBundles;
                                        totalWrittenBundles += writtenBundles;
                                        totalWrittenPolicies += writtenPolicies;
                                        totalTrending += trending;
                                        totalTrendingItems += trendingItems;
                                        totalSelfGenerated += selfGenerated;
                                        totalOneCallClose += oneCallClose;
                                    });
                                    
                                    return (
                                    <tr key="totalRow">
                                        <th>Total</th>
                                        <td style={{textAlign: 'center'}} key="totalCount">
                                            <Text>{totalCount}</Text>
                                        </td>
                                        <td style={{textAlign: 'center'}} key="totalQuotedPolicies">
                                            <Text>{this.state.dnqFilter == "all" ? totalQuotedPolicies : totalQuotedPoliciesDnq}</Text>
                                        </td>
                                        <td style={{textAlign: 'center'}} key="totalQuotedItems">
                                            <Text>{this.state.dnqFilter == "all" ? totalQuotedItems : totalQuotedItemsDnq}</Text>
                                        </td>
                                        <td style={{textAlign: 'center', fontWeight: 'bold'}} key="totalWrittenHouseholds">
                                            <Text>{totalWrittenHouseholds}</Text>
                                        </td>
                                        <td style={{textAlign: 'center', fontWeight: 'bold'}} key="totalWrittenPolicies">
                                            <Text>{totalWrittenPolicies}</Text>
                                        </td>
                                        <td style={{textAlign: 'center', fontWeight: 'bold'}} key="totalWrittenItems">
                                            <Text>{totalWrittenItems}</Text>
                                        </td>
                                        <td style={{textAlign: 'center', fontWeight: 'bold'}} key="totalWrittenPremium">
                                            <Text>{new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD"
                                            }).format(totalWrittenPremium)}</Text>
                                        </td>
                                        {this.state.trendingFilter == "premium" ? 
                                            <td style={{textAlign: 'center', fontWeight: 'bold'}} key="totalTrending">
                                                <Text>{new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                }).format(totalTrending)}</Text>
                                            </td>
                                            :
                                            <td style={{textAlign: 'center', fontWeight: 'bold'}} key="totalTrendingItems">
                                                <Text>{Math.round(totalTrendingItems)}</Text>
                                            </td>
                                        }
                                        <td style={{textAlign: 'center', fontWeight: 'bold'}} key="totalCloseRate">
                                            <Text>
                                            {this.state.dnqFilter == "all" ? 
                                                new Intl.NumberFormat("en-US", {
                                                    style: "percent",
                                                    maximumFractionDigits: 2
                                                }).format(totalQuotedPolicies > 0 ? totalWrittenPolicies / totalQuotedPolicies : 0)
                                                :
                                                new Intl.NumberFormat("en-US", {
                                                    style: "percent",
                                                    maximumFractionDigits: 2
                                                }).format(totalQuotedPoliciesDnq > 0 ? totalWrittenPolicies / totalQuotedPoliciesDnq : 0)
                                            }
                                            </Text>
                                        </td>
                                        <td style={{textAlign: 'center'}} key="totalQuotedBundles">
                                            <Text>{totalQuotedBundles}</Text>
                                        </td>
                                        <td style={{textAlign: 'center'}} key="totalQuotedBundlesRate">
                                            <Text>
                                            {new Intl.NumberFormat("en-US", {
                                                style: "percent",
                                                maximumFractionDigits: 2
                                            }).format(totalCount > 0 ? totalQuotedBundles / totalCount : 0)}
                                            </Text>
                                        </td>
                                        <td style={{textAlign: 'center'}} key="totalWrittenBundles">
                                            <Text>{totalWrittenBundles}</Text>
                                        </td>
                                        <td style={{textAlign: 'center'}} key="totalWrittenBundlesRate">
                                            <Text>
                                            {totalWrittenHouseholds > 0 ? new Intl.NumberFormat("en-US", {
                                                style: "percent",
                                                maximumFractionDigits: 2
                                            }).format(totalWrittenBundles / totalWrittenHouseholds) : '0%'}
                                            </Text>
                                        </td>
                                        <td style={{textAlign: 'center' }} key="totalSelfGenerated">
                                            <Text>{totalSelfGenerated}</Text>
                                        </td>
                                        <td style={{textAlign: 'center' }} key="totalOneCallClose">
                                            <Text>{totalOneCallClose}</Text>
                                        </td>
                                        <td style={{textAlign: 'center' }} key="totalOneCallClosePercent">
                                            <Text>
                                                {totalWrittenHouseholds > 0 ? new Intl.NumberFormat("en-US", {
                                                    style: "percent",
                                                    maximumFractionDigits: 2
                                                }).format(totalOneCallClose / totalWrittenHouseholds) : '0%'}
                                            </Text>
                                        </td>
                                    </tr>
                                )
                                }} />
                            }
                        </Col>
                    </Row>
                </div>
                <Modal 
                    width="25%"
                    visible={this.state.modalVisible}
                    title="SELECT COLUMNS"
                    onOk={this.handleCloseModal}
                    onCancel={this.handleCloseModal}
                    footer={[
                        <Row style={{paddingBottom: 10}}>
                            <Col sm={12}>
                                <Button style={{ float: 'left' }} type="primary" shape="round" onClick={this.handleCloseModal}>
                                    OK
                                </Button>
                            </Col>
                        </Row>,
                    ]}>
                        <Checkbox.Group style={{ width: '100%' }} onChange={this.handleColumnChange}>
                            <Row>
                                {summaryColumns.map(c => 
                                    <Col span={8} key={c.key}>
                                        <Checkbox value={c.caption}>{c.caption}</Checkbox>
                                    </Col>
                                )}
                            </Row>
                        </Checkbox.Group>
                </Modal>
                {/* </Card> */}
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ActivitySummary);                   